import React from "react";
import { Link } from "gatsby";
import Headroom from "react-headroom";
import g from "glamorous";
import { css } from "glamor";
import NavMobile from "./NavMobile";
import ContactButton from "./ContactButton";
import LanguageSwitcher from "./LanguageSwitcher";
import { FaSearch } from "react-icons/fa";
import { MdMoreHoriz } from "react-icons/md";

css.global("header a", { color: "white" });
css.global("header a", { textDecoration: "none" });

css.global("header a:hover", { opacity: ".8" });

css.global(".menudrop-appear", { opacity: ".01" });
css.global(".menudrop-appear.menudrop-appear-active", { opacity: "1" });
css.global(".menudrop-appear.menudrop-appear-active", {
  transition: "all .5s ease-in",
});

const mediaQueries = {
  phone: "@media only screen and (max-width: 991px)",
};

const Nav = g.nav({
  display: `flex`,
  width: `100%`,
  maxWidth: `1380px`,
  margin: `0 auto`,
});

const Burger = g.button({
  display: `none`,
  marginRight: `auto`,
  zIndex: `2`,
  position: `absolute`,
  padding: `24px 15px 15px`,
  cursor: `pointer`,
  transitionProperty: `opacity, filter`,
  transitionDuration: `0.15s`,
  transitionTimingFunction: `linear`,
  font: `inherit`,
  color: `inherit`,
  textTransform: `none`,
  backgroundColor: `transparent`,
  border: `0`,
  margin: `0`,
  overflow: `visible`,
  top: `auto`,
  bottom: `0`,
  outline: `none`,
  [mediaQueries.phone]: {
    display: `flex`,
    height: `100%`,
    alignItems: `center`,
  },
  ".toggled &": { marginRight: `auto` },
});

const HamburgerBox = g.span({
  width: `40px`,
  height: `24px`,
  display: `inlineBlock`,
  position: `relative`,
});

const HamburgerInner = g.span({
  display: `block`,
  top: `50%`,
  marginTop: `-2px`,
  width: `30px`,
  height: `2px`,
  backgroundColor: `white`,
  borderRadius: `4px`,
  position: `absolute`,
  transitionDuration: `0.13s`,
  transitionDelay: `0.13s`,
  transitionTimingFunction: `cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
  ".toggled &": {
    transform: `translate3d(0, -10px, 0) rotate(-45deg)`,
    transitionDelay: `0.22s`,
    transitionTimingFunction: `cubic-bezier(0.215, 0.61, 0.355, 1)`,
  },
  "&::before": {
    width: `30px`,
    height: `2px`,
    backgroundColor: `white`,
    borderRadius: `4px`,
    position: `absolute`,
    transitionProperty: `transform`,
    transitionDuration: `0.15s`,
    transitionTimingFunction: `ease`,
    content: `""`,
    display: `block`,
    top: `-10px`,
    transition: `top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
    ".toggled &": {
      top: `0`,
      transform: `rotate(-90deg)`,
      transition: `top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1)`,
    },
  },
  "&::after": {
    width: `30px`,
    height: `2px`,
    backgroundColor: `white`,
    borderRadius: `4px`,
    position: `absolute`,
    transitionProperty: `transform`,
    transitionDuration: `0.15s`,
    transitionTimingFunction: `ease`,
    content: `""`,
    display: `block`,
    top: `-20px`,
    transition: `top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear`,
    ".toggled &": {
      top: `0`,
      opacity: `0`,
      transition: `top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear`,
    },
  },
});

const Logo = g.div({
  display: `flex`,
  marginRight: `auto`,
  alignItems: `center`,
  height: `100%`,
  fontWeight: `500`,
  fontSize: `18px`,
  marginTop: `1px`,
  letterSpacing: `.06em`,
  paddingLeft: `10px`,
  [mediaQueries.phone]: {
    width: `100%`,
    textAlign: `center`,
    margin: `0 0 0 65px`,
  },
  ".toggled &": {},
});

const Ul = g.ul({
  display: `flex`,
  height: `100%`,
  padding: `0px`,
  listStyle: `none`,
  alignItems: `stretch`,
  // eslint-disable-next-line
  [".toggled"]: {
    top: `65px`,
    backgroundColor: `#3c4d68`,
    height: `270px`,
    transition: `all .3s`,
    transitionTimingFunction: `ease-in-out`,
  },
  [mediaQueries.phone]: {
    display: `none`,
  },
});

const Li = g.li({
  padding: `0 .7em`,
  letterSpacing: `0.03em`,
  display: `flex`,
  alignItems: `center`,
});

const cssLink = css({
  color: "white",
  fontSize: `1em`,
  fontWeight: `500`,
  textDecoration: `none`,
  borderBottom: `none`,
  transition: `all .3s`,
  padding: `0 0 3px 0`,
  margin: `0`,
  position: `relative`,
  "::before": {
    content: " ",
    position: `absolute`,
    width: `100%`,
    height: `2px`,
    bottom: `0`,
    left: `0`,
    backgroundColor: `#fff`,
    visibility: `invisible`,
    transform: `scaleX(0)`,
    transition: `all 0.3s ease-in-out 0s`,
  },
  ":hover": {
    transition: `all .3s`,
    border: `none`,
    background: `none`,
    padding: `0 0 3px 0`,
    margin: `0`,
    opacity: `1`,
    "::before": {
      visibility: `visible`,
      transform: `scaleX(1)`,
      transition: `all 0.3s ease-in-out 0s`,
    },
  },
  // eslint-disable-next-line
  [".current"]: {
    transition: `all .3s`,
    border: `none`,
    background: `none`,
    padding: `0 0 3px 0`,
    margin: `0`,
    opacity: `1`,
    "::before": {
      visibility: `visible`,
      transform: `scaleX(1)`,
      transition: `all 0.3s ease-in-out 0s`,
    },
  },
});

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { toggled: false };
    this.toggleNav = this.toggleNav.bind(this);
  }

  toggleNav() {
    const currentState = this.state.toggled;
    this.setState({ toggled: !currentState });
  }

  render() {
    const style = {
      height: "52px",
      width: "100%",
      backgroundColor: "#3c4d68",
      color: "rgba(255,255,255,0.92)",
      display: "flex",
    };

    return (
      <header className={this.state.toggled ? "toggled" : "desktop"}>
        <Headroom style={style}>
          <Nav>
            <Burger
              onClick={this.toggleNav}
              aria-label="Ouvrir menu de navigation"
            >
              <HamburgerBox>
                <HamburgerInner />
              </HamburgerBox>
            </Burger>
            <Logo>
              <Link {...cssLink} to="/">
                Dr Cédric Kron
              </Link>
            </Logo>

            <div>
              {this.state.toggled ? (
                <NavMobile
                  className={this.state.toggled ? "toggled" : "desktop"}
                  pathEn={this.props.pathEn}
                />
              ) : (
                <Ul className={this.state.toggled ? "toggled" : "desktop"}>
                  <Li>
                    <Link
                      {...cssLink}
                      to="/indications/"
                      className={
                        this.props.current === "interventions" && "current"
                      }
                    >
                      Interventions
                    </Link>
                  </Li>
                  <Li>
                    <Link
                      {...cssLink}
                      to="/avant-apres/"
                      className={
                        this.props.current === "avant-apres" && "current"
                      }
                    >
                      Avant - Après
                    </Link>
                  </Li>
                  <Li>
                    <Link
                      {...cssLink}
                      to="/chirurgien-esthetique-paris/"
                      className={
                        this.props.current === "qualifications" && "current"
                      }
                    >
                      Qualifications
                    </Link>
                  </Li>
                  <Li>
                    <Link {...cssLink} to="/centre-esthetique-paris/">
                      Centre esthétique
                    </Link>
                  </Li>
                  <Li>
                    <Link
                      {...cssLink}
                      to="/equipe-cabinet/"
                      className={
                        this.props.current === "equipe-cabinet" && "current"
                      }
                    >
                      L'Équipe
                    </Link>
                  </Li>

                  <Li>
                    <Link
                      {...cssLink}
                      to="/consultations/"
                      className={
                        this.props.current === "consultations" && "current"
                      }
                    >
                      Consultations
                    </Link>
                  </Li>
                  <Li>
                    <Link
                      {...cssLink}
                      to="/contactez-nous/"
                      className={this.props.current === "contact" && "current"}
                    >
                      Contact
                    </Link>
                  </Li>
                  <Li
                    css={{
                      padding: `0 1em`,
                      position: `relative`,
                      color: `white`,
                      "& .submenu": { display: `none` },
                      ":hover": {
                        opacity: 1,
                        "& .submenu": { display: `block` },
                      },
                    }}
                  >
                    <MdMoreHoriz
                      css={{ fontSize: `1.5em`, paddingBottom: `0em` }}
                    />
                    <SubUl className="submenu">
                      <SubLi>
                        {" "}
                        <Link {...cssSubLink} to="/actualites/">
                          Actualités
                        </Link>
                      </SubLi>
                      <SubLi>
                        {" "}
                        <Link {...cssSubLink} to="/temoignages-avis-patients/">
                          Témoignages
                        </Link>
                      </SubLi>
                      <SubLi>
                        <Link {...cssSubLink} to="/video-chirurgie-esthetique/">
                          Vidéos
                        </Link>
                      </SubLi>
                      <SubLi>
                        <Link {...cssSubLink} to="/revue-de-presse/">
                          Revue de presse
                        </Link>
                      </SubLi>
                      <SubLi>
                        <Link {...cssSubLink} to="/recherche/">
                          <FaSearch /> Rechercher
                        </Link>
                      </SubLi>
                      <SubLi>
                        <LanguageSwitcher
                          pathEn={this.props.pathEn}
                          {...cssSwitcher}
                        />
                      </SubLi>
                    </SubUl>
                  </Li>
                </Ul>
              )}
              <ContactButton width="44px" height="33px" />
            </div>
          </Nav>
        </Headroom>
      </header>
    );
  }
}

const SubUl = g.ul({
  top: `51px`,
  right: `0`,
  margin: 0,
  position: `absolute`,
  listStyleType: `none`,
  width: `175px`,
  backgroundColor: `#3c4d68`,
  border: `1px solid #3c4d68`,
  borderRadius: `0 0 3px 3px`,
  borderBottom: `none`,
});

const SubLi = g.li({
  padding: `0`,
  borderBottom: `1px solid white`,
  "&:last-of-type": {
    borderBottom: `none`,
  },
});

const cssSubLink = css({
  display: `block`,
  padding: `.7em .85em .7em`,
  fontWeight: `500`,
  color: `white`,
  textDecoration: `none`,
  letterSpacing: `0.02em`,
  ":hover": {
    opacity: `.98`,
  },
});

const cssSwitcher = css({
  padding: `12px`,
});
