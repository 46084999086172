import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import g from "glamorous";
import PageLink from "../components/PageLink";
import Menu from "../components/Menu";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";

const Title = g.h1({
  textAlign: `center`,
  fontSize: `32px`,
  color: `#3c4d68`,
  margin: `2em auto 1em`,
  fontVariant: `none`,
  fontWeight: `600`,
  letterSpacing: `.01em`,
  lineHeight: `44px`,
  "@media(max-width: 767px)": {
    fontSize: `23px`,
    LineHeight: `31px`
  }
});

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  const Pages = edges
    .filter(edge => !!edge.node.frontmatter.title) // You can filter your posts based on some criteria
    .map(edge => <PageLink key={edge.node.id} post={edge.node} />);

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`}>
        <Helmet>
          <title>
            Plan du site | Cédric Dr Kron, Chirurgien esthetique à Paris
          </title>
          <meta
            name="description"
            content="Retrouvez toutes les pages du site internet du Dr Cédric Kron, chirurgien esthétique à Paris qualifié en en Chirurgie Esthétique, Plastique et Reconstructrice exerçant."
          />
          <html lang="fr" />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Plan du site",
          "item": "https://www.dr-kron.com/plan-du-site/"
          }]
              }
    `}</script>
        </Helmet>
        <Menu pathEn="/en/" />
        <Container>
          <Row>
            <Col span={{ xs: 12 / 12, lg: 12 / 12 }}>
              <Title>Plan du site</Title>
              <div>{Pages}</div>
            </Col>
          </Row>
        </Container>
      </g.Div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query SiteMapQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            path
            title
          }
        }
      }
    }
  }
`;
