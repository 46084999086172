import React from "react";
import { Link } from "gatsby";
import { css } from "glamor";

export default ({ ...props }) => (
  <Link to={props.pathEn} {...cssSubLink}>
    <span role="img" aria-label="English flag - drapeau anglais">
      🇬🇧
    </span>{" "}
    English version
  </Link>
);

const cssSubLink = css({
  display: `block`,
  padding: `0.6em 0 .7em .6em`,
  fontWeight: `500`,
  color: `white`,
  textDecoration: `none`,
  letterSpacing: `0.02em`
});
