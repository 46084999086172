import React from "react";
import { Link } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import LanguageSwitcher from "./LanguageSwitcher";

const bounce = css.keyframes({
  "0%": { transform: "translateX(-100%)", opacity: 0.3 },
  "20%": { opacity: 0.5 },
  "100%": { transform: "translateX(0)", opacity: 1 },
});

const Nav = g.div({
  animation: `${bounce} .3s ease-out`,
  width: `100%`,
  position: `absolute`,
  top: `45px`,
  left: `0px`,
  display: `flex`,
  flexDirection: `column`,
  backgroundColor: `#3c4d68`,
  transition: `all 0.5s`,
  paddingTop: `.7em`,
});

const NavItem = g.div({
  padding: `.3em 1.3em`,
  fontWeight: `500`,
  fontSize: `17px`,
  letterSpacing: `.04em`,
  "::before": {
    content: "•",
    paddingRight: `.4em`,
  },
});

const NavItemRight = g.div({
  padding: `.25em 1.4em`,
  fontWeight: `500`,
  fontSize: `17px`,
  letterSpacing: `.04em`,
  textAlign: `right`,
});

const Questions = g.div({
  fontSize: `13px`,
  padding: `0 1.8em 0`,
  marginTop: `2px`,
  textAlign: `right`,
});

const cssLink = css({
  padding: `.2em 4em .2em .3em`,
});

export default ({ children, ...props }) => (
  <Nav>
    <NavItem>
      <Link to="/" {...cssLink}>
        Accueil
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/indications/" {...cssLink}>
        Interventions
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/techniques/" {...cssLink}>
        Techniques
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/avant-apres/" {...cssLink}>
        Avant - Après
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/chirurgien-esthetique-paris/" {...cssLink}>
        Qualifications
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/centre-esthetique-paris/" {...cssLink}>
        Centre esthétique
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/equipe-cabinet/" {...cssLink}>
        L'équipe
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/temoignages-avis-patients/" {...cssLink}>
        Témoignages
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/consultations/" {...cssLink}>
        Consultations
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/actualites/" {...cssLink}>
        Actualités
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/revue-de-presse/" {...cssLink}>
        Presse
      </Link>
    </NavItem>
    <NavItem>
      <Link to="/video-chirurgie-esthetique/" {...cssLink}>
        Vidéos
      </Link>
    </NavItem>

    <Questions>Vous avez une question ?</Questions>
    <NavItemRight>
      <Link to="/contactez-nous/">
        <FaEnvelope css={{ margin: `0 10px 3px 0` }} />
        Contactez-nous
      </Link>
    </NavItemRight>
    <NavItemRight css={{ margin: `0` }}>
      <a href="tel:0145628500">
        <FaPhone css={{ fontSize: `20px`, margin: `0 12px 3px 0` }} />
        01 45 62 85 00
      </a>
    </NavItemRight>
    <NavItemRight css={{ margin: `0` }}>
      <Link to="/recherche/">
        <FaSearch css={{ fontSize: `20px`, margin: `0 12px 3px 0` }} />
        Faire une recherche
      </Link>
    </NavItemRight>
    <NavItemRight css={{ margin: `-12px 0 1.2em` }}>
      <LanguageSwitcher pathEn={props.pathEn} />
    </NavItemRight>
  </Nav>
);
